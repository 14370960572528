<template>
  <div class="cookie-page">
    <div class="cookie-content" >
      <MolteniLogo class="logo mb-6" @click="$router.push({name: 'Home'})"/>
      <div ref="cookiecontent"></div>

      <Footer :relative="true"></Footer>
    </div>
  </div>
</template>

<script>
import MolteniLogo from '@/assets/svg/molteni-logo-dark.svg'
import Footer from '../organisms/Footer.vue'

export default {
  name: 'CookiePage',
  components: {
    MolteniLogo, Footer
  },
  mounted () {
    let lang = 'EN'
    if (this.$route.params && this.$route.params.language) {
      lang = this.$route.params.language.toUpperCase()
    }

    if (this.$root.$language.current) {
      lang = this.$root.$language.current.toUpperCase()
    }

    if (lang === 'CN') {
      lang = 'EN'
    }

    const ckeditor = document.createElement('script')
    ckeditor.setAttribute('id', 'CookieDeclaration')
    console.log(lang)
    ckeditor.setAttribute('data-culture', lang)
    ckeditor.setAttribute('src', 'https://consent.cookiebot.com/a8cda3ff-a187-4a65-8686-f61904bcd155/cd.js')

    if (this.$refs.cookiecontent) {
      this.$refs.cookiecontent.appendChild(ckeditor)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep strong {
  font-weight: bold !important;
}

.cookie-page {
  background-color: $c-white;
  color: $c-black;
  position: absolute;
  top: 0;

  ::v-deep p {
    margin-bottom: 0 !important;
  }

  ::v-deep a,
  ::v-deep span,
  ::v-deep div,
  ::v-deep p {
    font-size: 18px;
    @include breakpoint('md-and-down') {
      font-size: 14px;
    }
  }

  ::v-deep #CookieDeclarationChangeConsent { //stylelint-disable
    margin-top: 24px;
  }
}

.cookie-content {
  min-height: 100%;
  height: 100%;
  width: 80%;
  margin: 5% 10%;

  @include breakpoint('xs-only') {
    width: 90%;
    margin: 2% 5%;
  }

  .logo {
    max-width: 300px !important;
  }
}

</style>
