<template>
  <div class="home-page-container" :class="{'mobile-landscape': (viewPort.width > viewPort.height) && $root.platform.mobile}">
    <transition name="fade">
      <div v-if="isVideo || isIntro">
        <vue-plyr @ended="onVideoFinished" class="player" ref="plyr"
          :class="[
            {'vertical': (viewPort.width / viewPort.height) > (16/9)},
          ]">
          <video v-if="$route.params.language === 'cn'" :src="'/media/' + $root.currentSettings[$route.params.language+'MainVideo'].id"></video>
          <video v-else-if="$root.currentSettings[$route.params.language+'MainVideoFile']" :src="'/media/' + $root.currentSettings[$route.params.language+'MainVideoFile'].id"></video>
          <div v-else data-plyr-provider="vimeo"
            :data-plyr-embed-id="videoURL"></div>
        </vue-plyr>

        <div class="video-control">
          <div v-if="!$root.platform.ios" class="control pointer pa-9 ma-0" @click="audioToggle">
            <IconAudio :playing="audioState"></IconAudio>
          </div>
          <div class="control pointer pa-9 ma-0" @click="onVideoFinished">
            <p class="uppercase mb-0 skip-intro">{{ $root.allLanguages[$route.params.language].skipIntro }}</p>
          </div>
        </div>

        <MolteniLogo class="logo-molteni video"></MolteniLogo>
      </div>
    </transition>
    <transition name="fade" appear>
      <div v-if="!isVideo && !isIntro" class="home-welcome">

        <MolteniLogo class="logo-molteni"></MolteniLogo>
        <div class="mobile-welcome">
          <p>{{ $root.allLanguages[$route.params.language].saloneDescription }}</p>
        </div>

        <div class="scroll-container"
          :class="[
            {'active': hitActive === 'collection'},
            {'disabled': hitActive === 'salone'}
          ]"
        >
          <div class="content-bg" :style="{ backgroundImage: 'url(' + getOptimizedImage(this.$root.saloneSettings.mainLandingImage) +')'}"></div>
          <div class="content-large">

            <div class="top" cols="12" md="6">
              <p class="mt-3 mt-lg-6">{{ $root.allLanguages[$route.params.language].saloneDescription }}</p>
            </div>
            <div class="center">
              <h2 class="mt-md-6 bodoni uppercase">{{ $root.allLanguages[$route.params.language].saloneTitle }}</h2>
              <p class="mt-3 uppercase">{{ $root.allLanguages[$route.params.language].saloneSubtitle }}</p>
            </div>
            <div class="hit" @mouseover="hitActive = 'collection'" @mouseout="hitActive = ''">
              <div class="mt-6 mt-md-12 entry-link" @click="onStartVideoClick($root.saloneSettings)">
                <ArrowRight class="arrow svg-fill-white inline-block"></ArrowRight>
                <p class="start uppercase inline-block mb-0 bold letter-spacing-25 ml-0">{{ $root.allLanguages[$route.params.language].welcomeButtonTextCopy }}</p>
                <ArrowRight class="arrow mobile svg-fill-white inline-block"></ArrowRight>
              </div>
            </div>
          </div>

          <!--<HomeQuote></HomeQuote>-->
        </div>
        <div class="mobile-separator">
          <div class="up">
            <div class="arrow-container"><IconArrowUp  class="absolute-center svg-fill-brown"></IconArrowUp></div>
            <p class="uppercase">{{ $root.allLanguages[$route.params.language].swipeUp }}</p>
          </div>
          <div class="down">
            <div class="arrow-container"><IconArrowDown class="absolute-center svg-fill-brown"></IconArrowDown></div>
            <p class="uppercase">{{ $root.allLanguages[$route.params.language].swipeDown }}</p>
          </div>
        </div>
        <div class="scroll-design"
          :class="[
            {'active': hitActive === 'salone'},
            {'disabled': hitActive === 'collection'}
          ]"
          >
          <div class="content-bg" :style="{ backgroundImage: 'url(' + getOptimizedImage(this.$root.collectionSettings.mainLandingImage) +')'}"></div>
          <div class="content-small">
            <div class="center">
              <h2 class="mt-md-6 bodoni uppercase">{{ $root.allLanguages[$route.params.language].welcomeTitleCopy }}</h2>
              <p class="mt-3 uppercase">{{ $root.allLanguages[$route.params.language].molteniCompound }}</p>
            </div>
            <div class="hit" @mouseover="hitActive = 'salone'" @mouseout="hitActive = ''">
              <div class="mt-6 mt-md-12 entry-link" @click="onStartVideoClick($root.collectionSettings)">
                <ArrowRight class="arrow svg-fill-white inline-block"></ArrowRight>
                <p class="start uppercase inline-block mb-0 bold letter-spacing-25 ml-0">{{ $root.allLanguages[$route.params.language].welcomeButtonTextCopy }}</p>
                <ArrowRight class="arrow mobile svg-fill-white inline-block"></ArrowRight>
              </div>
            </div>
          </div>
          <!--<HomeQuote></HomeQuote>-->
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// import HomeQuote from '../atoms/HomeQuote.vue'
import Vue from 'vue'
import VuePlyr from 'vue-plyr'
import { Viewport } from '@monogrid/vue-lib'

import ArrowRight from '@/assets/svg/arrow-long-right.svg'
import IconAudio from '@/atoms/SoundIconWithAnimation.vue'
import MolteniLogo from '@/assets/svg/molteni-logo-white.svg'
import IconArrowUp from '@/assets/svg/arrow-up.svg'
import IconArrowDown from '@/assets/svg/arrow-down.svg'
import { optimizedImageURL } from '@/services/OptimizedImage'

// The second argument is optional and sets the default config values for every player.
Vue.use(VuePlyr, {
  plyr: {
    fullscreen: { enabled: false },
    hideControls: true,
    autoplay: false,
    playsinline: true,
    vimeo: {
      dnt: false
    }
  },
  emit: ['ended', 'ready']
})

export default {
  name: 'Home',
  mixins: [Viewport],

  data () {
    return {
      isVideo: false,
      isIntro: false,
      audioState: true,
      hitActive: '',
      videoURL: null
    }
  },

  components: {
    MolteniLogo, ArrowRight, IconAudio, IconArrowUp, IconArrowDown
  },

  mounted () {
    // this.player.play()
    // preload landing room
    this.$events.emit('preloadRoom', this.$root.currentSettings.landingRoom._slug)
  },

  methods: {
    getOptimizedImage (img) {
      return optimizedImageURL(img, {
        maxWidth: 1920,
        format: window.Modernizr && !!window.Modernizr.webp ? 'webp' : 'jpeg',
        quality: 70
      })
    },
    onStartVideoClick (setting) {
      this.$root.currentSettings = setting
      this.$root.updateFloorBySetting()

      if (this.$route.params.language !== 'cn' && !this.$root.currentSettings[this.$route.params.language + 'MainVideoFile']) {
        this.videoURL = this.getVimeoID(this.$root.currentSettings[this.$route.params.language + 'MainVideo'])
        console.log('video url', this.videoURL)
      }
      console.log(this.$root.currentSettings, this.$root.floors)
      // console.log(this.$refs.plyr)

      this.isVideo = true
      setTimeout(() => {
        this.player.on('playing', this.onVideoPlaying)

        this.player.play()
        this.player.muted = false
        this.player.volume = 1
        this.$events.emit('videoOpen')
      }, 500)
    },

    audioToggle () {
      if (this.player) {
        this.audioState = !this.audioState
        this.player.volume = this.player.volume === 1 ? 0 : 1
      }
    },

    onVideoPlaying () {
      if (this.player.playing && !this.player.muted) {
        this.audioState = true
      }
    },

    onVideoFinished () {
      this.player.pause()

      this.$events.emit('videoClose')
      if (this.isIntro) {
        setTimeout(() => {
          this.player.autoplay = false
          this.player.stop()
          this.player.source = {
            type: 'video',
            sources: [
              {
                src: this.getVimeoID(this.$root.currentSettings[this.$route.params.language + 'MainVideo']),
                provider: 'vimeo'
              }
            ]
          }
        }, 500)

        this.isIntro = false
        this.isVideo = false
      } else {
        this.$router.push({
          name: 'Room',
          params: {
            floor: this.$root.currentSettings.landingFloor._slug,
            roomName: this.$root.currentSettings.landingRoom._slug
          }
        })
      }
    },

    getVimeoID (url) {
      const match = url.match(/https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/) // eslint-disable-line
      // console.log(match)
      return (match && match[3]) ? match[3] : false
    }
  },

  watch: {
  },

  computed: {
    player () {
      return this.$refs.plyr.player
    }
  },

  metaInfo: function () {
    return {
      // home page browser title
      title: this.$gettext('Home Page')
    }
  }
}
</script>

<style scoped lang="scss">
.home-page-container {
  width: 100vw;
  min-height: 100%;
  position: fixed;
  z-index: 1;
  background: $c-black;

  @include breakpoint('xs-only') {
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &.mobile-landscape {
    min-height: 100%;
    width: 100%;

    .player {
      width: 100%;
      height: 100%;
    }
  }
}

.content-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  filter: brightness(0.6);
}

.scroll-container {
  width: 65vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;

  @include breakpoint('xs-only') {
    position: sticky !important;
    top: 0;
    width: 100vw;
    height: auto;
    min-height: 70vh;
    z-index: 0;

    .top {
      display: none;
    }
  }

  &.active {
    width: 80vw;
    opacity: 1;

    .top {
      opacity: 0;
      transform: translateX(-150px);
    }
  }

  &.disabled {
    width: 50vw;
  }

  .hit {
    width: 80vw;
  }
}

.mobile-separator {
  display: none;
  content: '';
  position: sticky;
  top: -10vh;
  height: 320px;
  min-height: 40vh;
  width: 100vw;
  background-color: #12100c;
  z-index: 1;

  @include breakpoint('xs-only') {
    display: block;
  }

  .up,
  .down {
    left: 40px;
    width: 60%;

    p { // stylelint-disable-line
      margin-top: 20px;
    }
  }

  .up {
    position: absolute;
    top: -30px;
  }

  .down {
    position: absolute;
    top: 100%;
    margin-top: -20px;
  }

  .arrow-container {
    width: 40px;
    height: 40px;
    border-radius: 25px;
    background-color: $c-white;
    position: relative;
    margin-left: 10px;

    &::before {
      content: '';
      width: 60px;
      height: 60px;
      border-radius: 30px;
      border: 1px solid $c-white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.scroll-design {
  position: fixed;
  top: 0;
  right: 0;
  width: 35vw;
  height: 100vh;
  overflow: hidden;

  @include breakpoint('xs-only') {
    position: relative;
    width: 100vw;
    z-index: 0;
    overflow: visible;
  }

  &.active {
    width: 50vw;

    .center {
      opacity: 1;
    }
  }

  &.disabled {
    width: 20vw;
    filter: brightness(0.5);
  }

  .center {
    opacity: 0.5;
    @include breakpoint('xs-only') {
      margin-top: 360px !important;
    }
  }

  .hit {
    width: 50vw;
    @include breakpoint('xs-only') {
      width: 80%;
      margin: 20px 0;
    }
  }

  .content-bg {
    filter: brightness(0.4) !important;
  }
}

.mobile-welcome {
  position: fixed;
  top: 70px;
  left: 40px;
  width: 80%;
  display: none;
  z-index: 2;

  @include breakpoint('xs-only') {
    display: block;
  }
}

.home-welcome {
  @include breakpoint('xs-only') {
    position: absolute;
    top: 0;
  }
}

.content-large,
.content-small {
  position: absolute;
  height: 100vh;
}

.content-large {
  width: 65vw;
  @include breakpoint('xs-only') {
    width: 100%;
    height: auto;
    position: relative;
  }
}

.content-small {
  width: 35vw;
  @include breakpoint('xs-only') {
    position: absolute;
    width: 100%;
    bottom: -10vh;
  }
}

.scroll-container,
.scroll-design {
  transition: width 1s;
  background-size: cover;
  background-position: center;

  .content-bg {
    transform: scale(1.2);
    transition: transform 1s;
    filter: brightness(0.6);
    z-index: 0;
  }

  &.active {
    .content-bg {
      transform: none;
    }

    .entry-link p {
      opacity: 1;
    }
  }

  &.disabled {
    .top,
    .center,
    .entry-link {
      opacity: 0;
    }

    .top,
    .entry-link {
      opacity: 0;
      transform: translateX(-150px);
    }

    .center {
      opacity: 0;
      transform: translate(-150px, -50%);
    }

    .content-bg {
      filter: brightness(0.2);
    }
  }

  .top,
  .center,
  .entry-link {
    position: absolute;
    left: 40px;
    width: 60%;
    z-index: 0;
    transition: opacity 1s, transform 1s;

    @include breakpoint('lg-and-down') {
      width: 80%;
    }

    @include breakpoint('xs-only') {
      position: relative;
      opacity: 1;
      width: 80%;
      transform: none;
    }
  }

  .hit {
    position: absolute;
    height: 30%;
    bottom: 0;
    z-index: 1;

    @include breakpoint('xs-only') {
      position: relative;
      height: auto;
    }
  }

  .top {
    top: 80px;
  }

  .center {
    top: 50%;
    transform: translateY(-50%);
    @include breakpoint('xs-only') {
      transform: none;
      top: auto;
      margin-top: 220px;
    }
  }

  .entry-link {
    bottom: 40px;

    @include breakpoint('xs-only') {
      opacity: 1;
      bottom: auto;
      transform: none;
      width: auto;
    }

    p {
      opacity: 0;

      @include breakpoint('xs-only') {
        opacity: 1;
      }
    }
  }
}

.arrow {
  width: 100px;
  height: 50px;
  margin-left: -8px;

  @include breakpoint('sm-and-down') {
    display: none;
  }

  &.mobile {
    display: none;
    width: 60px;
    height: 30px;
    @include breakpoint('sm-and-down') {
      display: inline-block;
      margin-left: 0;
    }
  }
}

.logo-molteni {
  width: 245px;
  height: auto;
  position: fixed;
  left: 40px;
  top: 40px;
  z-index: 2;

  @include breakpoint('sm-and-down') {
    top: 20px;
  }

  &.video {
    left: 40px;
    transform: none;

    @include breakpoint('sm-and-down') {
      display: none;
    }
  }
}

.player {
  position: absolute;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  background-color: $c-black-100;
}

.video-control {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
}

.control {
  background-color: $c-black-60;
}

.skip-intro {
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 12px;
}

::v-deep .plyr__control--overlaid {
  background: $c-brown;
  padding: 50px;

  &:hover {
    background: $c-white !important;

    svg {
      fill: $c-brown;
    }
  }
}

::v-deep .plyr--full-ui input {
  color: $c-brown !important;
}

::v-deep .plyr--video .plyr__control.plyr__tab-focus,

::v-deep .plyr--video .plyr__control:hover,

::v-deep .plyr--video .plyr__control[aria-expanded=true] {
  background: $c-brown;
}

.vertical ::v-deep .plyr {
  height: 100vh;
  width: calc((100vh/9)*16);
}

::v-deep .plyr__video-wrapper {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

::v-deep .plyr {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: calc((100vw/16)*9);
}

.landing-content-container {
  position: relative;
  z-index: 1;
  width: 100vw;
  min-height: 100vh;

  @include breakpoint('sm-and-down') {
    height: auto;
  }
}

.entry-container {
  @include breakpoint('sm-and-down') {
    text-align: right;
  }
}

.entry-link {
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  padding: 20px 0;
  color: $c-white;
  transform: translateX(40px);
  cursor: pointer;
  height: 100px;
  white-space: nowrap;

  @include breakpoint('sm-and-down') {
    padding: 0;
    transform: none;
    margin-top: 85px;
    margin-right: 40px;
    text-align: left;
    transform: translateX(0);
  }

  p {
    position: absolute;
    width: 300px;
    margin-top: 10px;
    transition: margin 0.5s;

    @include breakpoint('md-and-down') {
      margin-top: 13px;
    }

    @include breakpoint('sm-and-down') {
      position: relative;
      width: auto;
      margin-top: 0;
      margin-left: -20px;
    }
  }

  &:hover p {
    margin-left: 24px !important;
  }

  &:hover::before {
    border: solid 2px $c-white;
    opacity: 1;
  }

  &::before {
    content: '';
    position: absolute;
    width: 158px;
    height: 158px;
    border-radius: 130px;
    opacity: 0.5;
    border: solid 1px $c-white;
    transform: translate(-50%, -50%);
    margin: 20px;
    margin-left: 40px;
    transition: all 0.25s;

    @include breakpoint('sm-and-down') {
      width: 100px;
      height: 100px;
      border-radius: 85px;
      right: 0;
      margin: 0;
      transform: translate(calc(50% - 30px), calc(-50% + 15px));
      left: auto;
      display: block;
    }
  }
}

.line {
  width: 180px;
  height: 1px;
  border-bottom: solid 1px $c-brown;
  transform: rotate(90deg);
  position: absolute;
  left: 2%;

  @include breakpoint('sm-and-down') {
    left: -45px;
  }
}

.fade-enter-active {
  transition: opacity 2.5s ease-out;

  .logo-molteni {
    transition: opacity 0.7s 0.5s ease-out;
  }

  h2 {
    transition: opacity 0.7s 0.7s ease-out;
  }

  p {
    transition: opacity 0.7s 0.9s ease-out;
  }

  .entry-link {
    transition: opacity 0.7s 1.3s ease-out;
  }

  ::v-deep .footer {
    transition: transform 0.5s 2s ease-out;
  }
}

.fade-leave-active {
  transition: opacity 0.5s ease-out;

  .logo-molteni,
  h2,
  p,
  .entry-link {
    transition: opacity 0.5s ease-out;
  }

  ::v-deep .footer {
    transition: transform 0.5s ease-out;
  }
}

.fade-enter,
.fade-leave-to {
  opacity: 1;

  .logo-molteni,
  h2,
  p,
  .entry-link {
    opacity: 0;
  }

  ::v-deep .footer {
    transform: translateY(100%);
  }
}
</style>
